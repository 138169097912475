import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type CodeMap = {
  [key: string]: string;
};

export const flagCode = (code: string) => {
  const codes: CodeMap = {
    en: "gb",
    nl: "nl",
    nb: "no",
    sv: "se",
  };

  if (codes[code]) return codes[code];

  return code;
};

export const humanFileSize = (size: number) => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));

  return (
    (size / Math.pow(1024, i)).toFixed(2) +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
};

export const userNameAcronym = (name: string) => {
  const split = name.split(/\s/);

  if (split.length === 1) return split[0].slice(0, 2).toUpperCase();

  return (
    split[0].slice(0, 1).toUpperCase() + split[1].slice(0, 1).toUpperCase()
  );
};
