import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import * as Sentry from "@sentry/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

import "./bootstrap";

import { TooltipProvider } from "./components/shadcn/tooltip";
import { AssignmentProvider } from "./hooks/useAssignment";
import { EventProvider } from "./hooks/useEvent";

import "../css/app.css";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,

  tracesSampleRate: 1.0,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const appName =
  window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

const importRewrite = (name: string) => {
  const rewritesFolders = ["Auth", "Profile"];

  if (rewritesFolders.includes(name.split("/")[0])) {
    return name.charAt(0).toLowerCase() + name.slice(1);
  }

  return name;
};

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  progress: {
    color: "#4B5563",
  },
  resolve: (name) =>
    resolvePageComponent(
      `./pages/${importRewrite(name)}.tsx`,
      import.meta.glob("./pages/**/*.tsx"),
    ),
  setup({ el, App, props }) {
    const root = createRoot(el);
    return root.render(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <EventProvider>
        <AssignmentProvider>
          <TooltipProvider>
            <App {...props} />
          </TooltipProvider>
        </AssignmentProvider>
      </EventProvider>,
    );
  },
});
