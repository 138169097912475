import { createContext, useContext, useState } from "react";

import { additionalDataProps, Assignment } from "@/types/assignment";

export const AssignmentContext = createContext<{
  addDialog: additionalDataProps | null;
  setAddDialog: (data: additionalDataProps | null) => void;
  activeAssignment: Assignment | null;
  setActiveAssignment: (assignment: Assignment | null) => void;
}>({
  addDialog: null,
  setAddDialog: () => {},
  activeAssignment: null,
  setActiveAssignment: () => {},
});

export const AssignmentProvider = ({ children }) => {
  const [addDialog, setAddDialog] = useState<additionalDataProps | null>(null);
  const [activeAssignment, setActiveAssignment] = useState<Assignment | null>(
    null,
  );

  return (
    <AssignmentContext.Provider
      value={{ addDialog, setAddDialog, activeAssignment, setActiveAssignment }}
    >
      {children}
    </AssignmentContext.Provider>
  );
};

export const useAssignment = () => {
  const context = useContext(AssignmentContext);
  if (!context) {
    throw new Error("useAssignment must be used within a AssignmentProvider");
  }

  return context;
};
