import { createContext, useContext, useEffect, useState } from "react";
import { Channel } from "laravel-echo";

export const EventContext = createContext<{
  channel: Channel | null;
  user: Channel | null;
  default: Channel | null;
  setChannel: (channel: string | null) => void;
  setUser: (user: number | null) => void;
}>({
  channel: null,
  user: null,
  default: null,
  setChannel: () => {},
  setUser: () => {},
});

export const EventProvider = ({ children }) => {
  const [channel, setChannel] = useState<string | null>(null);
  const [user, setUser] = useState<number | null>(null);

  const [broadcastChannel, setBroadcastChannel] = useState<Channel | null>(
    null,
  );
  const [broadcastUser, setBroadcastUser] = useState<Channel | null>(null);

  const [broadcastDefault, setBroadcastDefault] = useState<Channel | null>(
    null,
  );

  useEffect(() => {
    if (!channel) return;

    setBroadcastChannel(window.Echo.private(`channel.${channel}`));

    return () => {
      window.Echo.leave(`channel.${channel}`);
      setBroadcastChannel(null);
    };
  }, [channel]);

  useEffect(() => {
    if (!user) return;

    setBroadcastUser(window.Echo.private(`user.${user}`));

    return () => {
      window.Echo.leave(`user.${user}`);
      setBroadcastUser(null);
    };
  }, [user]);

  useEffect(() => {
    setBroadcastDefault(window.Echo.private(`default`));

    return () => {
      window.Echo.leave(`default`);
      setBroadcastDefault(null);
    };
  }, []);

  return (
    <EventContext.Provider
      value={{
        channel: broadcastChannel,
        user: broadcastUser,
        default: broadcastDefault,
        setChannel,
        setUser,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error("useEvent must be used within a EventProvider");
  }

  return context;
};
